
import { Component, Vue } from "vue-property-decorator";
import { UserService, GaodeService, WechatService } from "src/services";
import { Toast } from "vant";
import { tools } from "src/utils";
import { ILogin } from "src/model";
import { DesSecretUtils } from "src/utils";
import privacy from "../privacy.vue";
import agreement from "../agreement.vue";
import rightsAgreement from "../rights-agreement.vue";
import rightsPrivacy from "../rights-privacy.vue";
import globalConfig from "../../config/index";
import { nextTick } from "process";
const showRightsCode = globalConfig.showRightsCode;
// import sensorsUtils from "src/utils/sensorsUtils"

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate" // for vue-router 2.2+
]);

@Component({
    components:
    {
        agreement,
        privacy,
        rightsAgreement,
        rightsPrivacy
    }
})
export default class Login extends Vue
{
    /**
     * 显示协议弹框
     * @private
     * @returns boolean
     */
    private showOverlay: boolean = false;

    /**
     * 是否显示服务协议
     * @private
     * @returns boolean
     */
    private showAgreement: boolean = false;

    /**
     * 是否显示隐私协议
     * @private
     * @returns boolean
     */
    private showPrivacy: boolean = false;

    /**
     * 是否显示权益隐私协议
     * @private
     * @returns boolean
     */
    private showRightsAgreement: boolean = false;

    /**
     * 是否显示权益隐私协议
     * @private
     * @returns boolean
     */
    private showRightsPrivacy: boolean = false;

    /**
     * 是否默认记录密码
     * @private
     * @returns boolean
     */
    private isRememberPassword: boolean = false;

    /**
     * 是否同意协议
     * @private
     * @returns boolean
     */
    private checkedAgreement: boolean = false;

    /**
     * 登录数据模型
     * @private
     * @returns ILogin
     */
    private modelData: ILogin =
    {
        code: "", // 企业编号
        account: "", // 登入账号
        password: "" // 登入密码
    };

    /**
     * 是否显示密码
     * @private
     * @returns string
     */
    private showPwd: boolean = false;

    /**
     * 获取当前登入机构信息
     * @private
     * @returns void
     */
    private get tip(): any
    {
        let appid = localStorage.getItem("appid");
        let employerConfig = globalConfig.employerConfig[appid];
        return {
            accountTip: employerConfig && employerConfig.accountTip || "请输入手机号",
            pwdTip: employerConfig && employerConfig.accountTip || "请输入密码"
        };
    }

    /**
     * 获取当前登入机构信息
     * @private
     * @returns void
     */
    private get orgInfo(): any
    {
        return this.$store.getters.orgInfo || {};
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get showRights(): any
    {
        return showRightsCode.includes(localStorage.getItem("appid"));
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
     private created(): void
    {
        let loginDataStr= localStorage.getItem("loginData");
        if(loginDataStr)
        {
            this.isRememberPassword = true;
            this.modelData = JSON.parse(DesSecretUtils.encrypt(loginDataStr))
        }
        
        localStorage.removeItem("openid")
        this.modelData.code = localStorage.getItem("appid");
        // 获取机构信息
        this.getOrgInfo(this.modelData.code);
    }

    /**
     * 获取机构详情
     * @private
     * @returns void
     */
     private async getOrgInfo(code: string): Promise<void>
    {
        try
        {
            let {content: result} =  await UserService.instance.getOrgInfo(code);
            if(result.data)
            {
                this.$store.dispatch("setOrgInfo", result.data);
                if (result.data.isSelf) // 个人
                {
                    this.$router.replace({name: "register"});
                }
                else
                {
                    this.getGeolocation();
                }
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /* 获取当前位置信息
     * @private
     * @returns void
     */
    private async getGeolocation(): Promise<void>
    {
        let appid = localStorage.getItem("appid");
        let employerConfig = globalConfig.employerConfig[appid];
        if (employerConfig && employerConfig.unLocation)
        {
            return;
        }
        this.$store.dispatch("setLocation", null);
        if (await tools.isWechat()) // 如果是微信环境
        {
            this.weixinLocation();
        }
        else
        {
            let data = await GaodeService.instance.getBaiduLocation();
            this.$store.dispatch("setLocation", data);
        }
    }

    

    /**
     * 获取routeName
     * @private
     * @returns void
     */
     private get routeName(): any
    {
        return this.$route.query.routeName || "";
    }

    /**
     * 组件进来之前
     * @private
     * @returns void
     */
    private beforeRouteEnter(to, from, next): void
    {
        let appid = localStorage.getItem("appid");
        if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname)
        {
            location.assign(`/${appid}${to.fullPath}`);
        }
        else
        {
            next();
        }
    }

    /* 获取当前位置信息 -- 微信
     * @private
     * @returns void
     */
    private async weixinLocation(): Promise<void>
    {
        let currentUrl = location.href;
        let {content: result} = await WechatService.instance.getWechatJSSDK(currentUrl);
        let loca = await WechatService.instance.getWechatLocation(result); // 获取经纬度-腾讯
        let locat = await GaodeService.instance.getCity((loca as any).longitude, (loca as any).latitude); // 通过经纬度获取城市-高德
        this.$store.dispatch("setLocation", locat);
    }

    /* 同意协议
     * @private
     * @returns void
     */
    private async onAgree ()
    {
        this.checkedAgreement = true;
        this.showOverlay = false;
        // 点击同意之后直接登录
        try
        {
            let {content: result} =  await UserService.instance.login(this.modelData);
            // 绑定神策用户数据
            // sensorsUtils.bind("identity_Id",result.data.userId +"");
           
            if(this.isRememberPassword)
            {
                let loginData = DesSecretUtils.decrypt(JSON.stringify(this.modelData));
                localStorage.setItem("loginData", loginData)
            }
            else
            {
                localStorage.removeItem("loginData")
            }
            
            if(result.data)
            {
                this.$store.dispatch("setUserInfo", result.data);
                this.$nextTick(()=>
                {
                    this.$router.replace({name: "home"});
                })
                
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /* 忘记密码密码
     * @private
     * @returns void
     */
    private onRetrievePwd(): void
    {
       this.$router.push({name: "retrievePwd"});
    }

     /* 跳转隐私协议
     * @private
     * @returns void
     */
     private onPrivacy(): void
    {
        this.showPrivacy = true;
    }

    /* 关闭隐私协议
     * @private
     * @returns void
     */
    private closePrivacy(): void
    {
       this.showPrivacy = false;
    }

    /* 跳转用户协议
     * @private
     * @returns void
     */
    private onAgreement(): void
    {
       this.showAgreement = true;
    }

    /* 关闭用户协议
     * @private
     * @returns void
     */
    private closeAgreement(): void
    {
        this.showAgreement = false;
    }

    /* 跳转携保权益协议
     * @private
     * @returns void
     */
    private onRightsAgreement(): void
    {
       this.showRightsAgreement = true;
    }

    /* 关闭携保权益协议
     * @private
     * @returns void
     */
    private closeRightsAgreement(): void
    {
       this.showRightsAgreement = false;
    }

    /* 跳转携保隐私
     * @private
     * @returns void
     */
    private onRightsPrivacy(): void
    {
        this.showRightsPrivacy = true;
    }

    /* 跳转携保隐私
     * @private
     * @returns void
     */
    private closeRightsPrivacy(): void
    {
        this.showRightsPrivacy = false;
    }
}
